import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Spinner from '../../../components/spinner/Spinner';
import './../businessProfileConnect/BusinessProfileConnect.css';
import { Fragment } from 'react';
import './GridReportTool.css';
import { GetSalesBusiness, RemoveSalesBusiness, StoreSalesBusiness } from '../../../utils/StorageUtil';
import GridMapV5 from '../../../components/gridReport/v5/gridMap/GridMapV5';
import { toast } from 'react-toastify';
import {
	CreateSalesCompany,
	CreateSalesCompanyTask,
	DeleteSalesCompanyTask,
	GenerateSEOReportDataGrid,
	GetAdminDefaultSettings,
	GetReportSettings,
	GetSalesCompanyDetails,
	GetSalesCompanyTaskDetails,
	GetSalesCompanyTasks,
	UpdateSalesCompany,
	UpdateSalesCompanyTask,
	UpdateSalesCompanyTaskIndexes
} from '../../../Services';
import { useStateValue } from '../../../StateProvider';
import { copyToClipboard, createGuid, slugify } from '../../../utils/Common';
import moment from 'moment';
import html2canvas from 'html2canvas';
import { GenerateReportData, GetReportDataByCompanyId } from '../../../ReportAPI';
import ModalDialog from '../../../components/modalDialog/ModalDialog';
import ModalDialogContent from '../../../components/modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../components/modalDialog/modalDialogButtons/ModalDialogButtons';

const GridReportTool = () => {
	const [ { user, host }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isLoadingMap, setIsLoadingMap ] = useState(false);
	const [ isGenerating, setIsGenerating ] = useState(false);
	const [ isGeneratingAudit, setIsGeneratingAudit ] = useState(false);
	const [ isUpdatingAudit, setIsUpdatingAudit ] = useState(false);
	const [ selectedBusiness, setSelectedBusiness ] = useState();
	const [ companyData, setCompanyData ] = useState();
	const [ companyName, setCompanyName ] = useState();
	const [ areaRadius, setAreaRadius ] = useState();
	const [ keywords, setKeywords ] = useState();
	const [ selectedTask, setSelectedTask ] = useState();
	const [ selectedTaskGridData, setSelectedTaskGridData ] = useState();
	const navigate = useNavigate();
	const [ showGridReport, setShowGridReport ] = useState(false);
	const [ reportTasks, setReportTasks ] = useState();
	const [ dateCreated, setDateCreated ] = useState();
	const [ gridData, setGridData ] = useState();
	const [ auditData, setAuditData ] = useState({});
	const [ adminSettings, setAdminSettings ] = useState();
	const [ reportSettings, setReportSettings ] = useState();
	const { companyId, reportName, reportId, editCompanyId } = useParams();
	const [ showShareLinkCopiedMessage, setShowShareLinkCopiedMessage ] = useState(false);
	const [ changesMade, setChangesMade ] = useState(false);
	const [ selectedIndustryCategory, setSelectedIndustryCategory ] = useState();
	const [ auditKeywordData, setAuditKeywordData ] = useState();
	const [ showJsonOutputDialog, setShowJsonOutputDialog ] = useState(false);
	const [ jsonOutput, setJsonOutput ] = useState();
	const [ competitorsData, setCompetitorsData ] = useState();
	const [ competitorsDataForCopy, setCompetitorsDataForCopy ] = useState();
	const gridMapContainerRef = useRef();
	const settings = [
		{ type: 'profile', label: 'Categories', propValue: 'categories', responseType: 'bool' },
		{ type: 'profile', label: 'Address', propValue: 'Address', responseType: 'bool' },
		{ type: 'profile', label: 'Phone Number', propValue: 'Phone', responseType: 'bool' },
		{ type: 'profile', label: 'Website URL', propValue: 'Website', responseType: 'bool' },
		{ type: 'profile', label: 'Contact URL', propValue: 'ContactUrl', responseType: 'bool' },
		{ type: 'profile', label: 'Description', propValue: 'Description', responseType: 'bool' },
		{ type: 'profile', label: 'Business Hours', propValue: 'WorkTime', propSubValue: 'Data', responseType: 'bool' },
		{
			type         : 'reviews',
			label        : 'Total Reviews',
			propValue    : 'total',
			//printPropValue : 'total',
			responseType : 'int'
		},
		{ type: 'posts', label: 'Total Posts', propValue: 'total', responseType: 'int' },
		{ type: 'qa', label: 'Total Q&A', propValue: 'total', responseType: 'int' },
		{ type: 'profile', label: 'Total Images', propValue: 'TotalPhotos', responseType: 'int' },
		{ type: 'profile', label: 'Logo', propValue: 'Logo', responseType: 'bool' },
		{ type: 'profile', label: 'Main Image', propValue: 'MainImage', responseType: 'bool' },
		{ type: 'profile', label: 'Social Profiles', propValue: 'socialProfiles', responseType: 'bool' }
	];
	const kwSettings = [
		{ type: 'keywordsIn', label: 'Profile Categories', propValue: 'occurrencesInCategories', responseType: 'bool' },
		{ type: 'keywordsIn', label: 'Profile Title', propValue: 'occurrencesInTitle', responseType: 'bool' },
		{
			type         : 'keywordsIn',
			label        : 'Profile Description',
			propValue    : 'occurrencesInDescription',
			responseType : 'bool'
		},
		{
			type         : 'keywordsIn',
			label        : 'Profile Reviews (Last 20)',
			propValue    : 'occurrencesInReviewsLast20',
			responseType : 'int'
		},
		{ type: 'keywordsIn', label: 'Profile Posts', propValue: 'occurrencesInPosts', responseType: 'bool' },
		{ type: 'keywordsIn', label: 'Profile Q&A', propValue: 'occurrencesInQA', responseType: 'bool' },
		{ type: 'keywordsIn', label: 'Website Landing Page', propValue: 'occurrencesInHtml', responseType: 'bool' },
		{ type: 'keywordsIn', label: 'Website Pages Indexed', propValue: '', responseType: 'int' }
	];
	const reviewsLast20Settings = [
		{ label: 'Average Rating', propValue: 'averageRating', responseType: 'int' },
		{ label: 'Average Words Per Review', propValue: 'averageWordsCount', responseType: 'int' },
		{ label: 'Have Replies', propValue: 'replies', responseType: 'int' },
		{ label: 'Local Guide Reviews', propValue: 'localGuide', responseType: 'int' },
		{ label: 'Negative Reviews', propValue: 'negative', responseType: 'int' },
		{ label: 'Negative Reviews Have Replies', propValue: 'negativeWithReplies', responseType: 'int' }
	];
	const reviewsLast30DaysSettings = [
		{ label: 'Total Reviews', propValue: 'results', responseType: 'int' },
		{ label: 'Average Rating', propValue: 'averageRating', responseType: 'int' },
		{ label: 'Average Words Per Review', propValue: 'averageWordsCount', responseType: 'int' },
		{ label: 'Have Replies', propValue: 'replies', responseType: 'int' },
		{ label: 'Local Guide Reviews', propValue: 'localGuide', responseType: 'int' },
		{ label: 'Negative Reviews', propValue: 'negative', responseType: 'int' },
		{ label: 'Negative Reviews Have Replies', propValue: 'negativeWithReplies', responseType: 'int' }
	];

	useEffect(() => {
		getAdminSettings();
		getReportSettings();
	}, []);

	useEffect(() => {
		if (companyId) {
			const fetchData = async () => {
				await getCompanyDetails(companyId);
				await getCompanyReportTasks(companyId);
				setShowGridReport(true);
			};

			fetchData().catch((error) => {});
		}
	}, []);

	useEffect(
		() => {
			if (editCompanyId) {
				setIsLoadingMap(true);
				setShowGridReport(false);
				getCompanyDetails(editCompanyId);
				getCompanyReportTasks(editCompanyId, true);
			}
		},
		[ editCompanyId ]
	);

	useEffect(
		() => {
			if (reportId) {
				getReportTaskDetails(reportId, true);
				setShowGridReport(true);
			}
		},
		[ reportId ]
	);

	useEffect(() => {
		if (!companyId && !editCompanyId && !reportId) {
			setIsLoadingMap(true);

			let tempBusiness = GetSalesBusiness();

			if (tempBusiness) {
				setSelectedBusiness(tempBusiness);

				setAreaRadius(tempBusiness.isServiceArea ? 7 : 2.5);

				setGridData({
					googlePlaceId : tempBusiness.place_id,
					lat           : tempBusiness.lat,
					lng           : tempBusiness.lng
				});
			}

			setIsLoading(false);
			setIsLoadingMap(false);
		}
	}, []);

	useEffect(
		() => {
			if (window.mapReady) {
				setIsLoadingMap(false);
			}
		},
		[ window.mapReady ]
	);

	useEffect(
		() => {
			if (selectedBusiness) {
				setGridData({
					googlePlaceId : selectedBusiness.place_id,
					lat           : selectedBusiness.lat,
					lng           : selectedBusiness.lng
				});
			}
		},
		[ areaRadius, selectedBusiness ]
	);

	useEffect(
		() => {
			if (selectedTaskGridData) {
				getCompetitorsData();

				getAuditKeywordData(companyId, selectedTask.keyword);
			}
		},
		[ selectedTaskGridData ]
	);

	const getCompanyDetails = async (id) => {
		let response = await GetSalesCompanyDetails(id);

		if (response) {
			setCompanyData(response);
			setCompanyName(response.name);

			if (editCompanyId) {
				setGridData({
					googlePlaceId : response.googlePlaceId,
					lat           : response.lat,
					lng           : response.lng
				});
			}
		}
	};

	const getCompanyReportTasks = async (id) => {
		setIsLoading(true);

		let response = await GetSalesCompanyTasks(id);

		if (response && response.length > 0) {
			let tempTasks = response;
			let tempTask;

			if (reportName) {
				tempTasks = tempTasks.filter((x) => x.reportName === reportName);
			}

			if (tempTasks.length > 0) {
				setReportTasks(tempTasks);

				//sort by order index
				tempTasks = tempTasks.sort(function(a, b) {
					return a.orderIndex - b.orderIndex;
				});

				tempTask = tempTasks[0];
				setSelectedTask(tempTask);

				if (editCompanyId) {
					setAreaRadius(tempTask.gridRadius);

					setKeywords(tempTasks.map((task) => task.keyword).join('\n'));

					setIsLoadingMap(false);
				} else {
					await getReportTaskDetails(tempTask.id);
				}
			}
		} else {
			setAreaRadius(5);
		}

		setIsLoading(false);
	};

	const getReportTaskDetails = async (id, isSingleTask = false, isSelectedTask = false) => {
		if (!isSelectedTask) {
			setIsLoading(true);
		}

		let response = await GetSalesCompanyTaskDetails(id);

		if (response) {
			let tempTask = response;

			setAreaRadius(tempTask.gridRadius);

			setDateCreated(tempTask.lastUpdated || tempTask.dateCreated);

			if (isSingleTask) {
				let tempTasks = [ tempTask ];
				setReportTasks(tempTasks);

				setCompanyName(tempTask.salesCompanyName);
			}

			if (tempTask.content) {
				let gridReportData = JSON.parse(tempTask.content);

				if (gridReportData) {
					setSelectedTaskGridData(gridReportData);

					setGridData({
						googlePlaceId    : gridReportData.googlePlaceId,
						lat              : tempTask.lat,
						lng              : tempTask.lng,
						horizontalPoints : tempTask.gridPoints,
						verticalPoints   : tempTask.gridPoints,
						...(gridReportData.gridResponse && gridReportData.gridResponse.data
							? gridReportData.gridResponse.data[0]
							: null)
					});

					setIsLoadingMap(false);
				}
			} else {
				setIsLoadingMap(false);
			}

			if (tempTask.auditData) {
				setAuditData(JSON.parse(tempTask.auditData));
			}
		} else {
			setIsLoadingMap(false);
		}

		setIsLoading(false);
	};

	const getAdminSettings = async () => {
		let response = await GetAdminDefaultSettings();

		if (response && response.data) {
			setAdminSettings(response.data);
		}
	};

	const getReportSettings = async () => {
		let response = await GetReportSettings();

		if (response && response.data) {
			setReportSettings(response.data);
		}
	};

	const handleChange = async (e) => {
		if (e.target.name === 'areaRadius') {
			setAreaRadius(e.target.value);
			setChangesMade(true);
		} else if (e.target.name === 'keywords') {
			setKeywords(e.target.value);
		}
	};

	const generateReport = async (e) => {
		e.preventDefault();

		if (!keywords || keywords.trim() === '') {
			toast.error('Enter at least one keyword to continue');
			return;
		}

		let keywordsArrayForReport = [];

		if (editCompanyId && changesMade) {
			//radius has changed, thus create report for all keyword
			keywordsArrayForReport = keywords.split('\n').map((x) => x.trim());
		} else if (editCompanyId && !changesMade) {
			//run reports only for new keywords or for existing also if it's passed more than 7 days
			if (keywords && reportTasks && reportTasks.length > 0) {
				//first check if we changed or delete any of existing keywords
				reportTasks.forEach(async (reportTask) => {
					let existingKeyword = keywords
						.split('\n')
						.find((x) => x.toLowerCase() === reportTask.keyword.trim().toLowerCase());

					if (!existingKeyword) {
						//delete this task because the keyword is not anymore in the list
						const deleteTaskResponse = await DeleteSalesCompanyTask(editCompanyId, reportTask.id);

						if (!deleteTaskResponse) {
							console.log('Failed while delete task: ' + reportTask.id);
						}
					}
				});

				//go through keywords and create or update tasks
				keywords.split('\n').forEach((keyword) => {
					let existingTask = reportTasks.find(
						(x) => x.keyword.trim().toLowerCase() === keyword.trim().toLowerCase()
					);

					if (!existingTask) {
						//no existing task for this keyword, so add it to run report keywords list
						keywordsArrayForReport.push(keyword.trim());
					} else {
						let reportDate = existingTask.lastUpdated || existingTask.dateCreated;

						if (moment(reportDate).add(7, 'days').isBefore(moment(), 'day')) {
							//task exists for this keyword, but it was created more than 7 days ago
							keywordsArrayForReport.push(keyword.trim());
						}
					}
				});
			}
		} else {
			keywordsArrayForReport = keywords.split('\n').map((x) => x.trim());
		}

		if (keywordsArrayForReport && keywordsArrayForReport.length > 5) {
			toast.error('You can set max 5 keywords');
			return;
		}

		if (editCompanyId && keywordsArrayForReport.length === 0) {
			//no any keyword changed or all keywords ran in the last 7 days, just redirect to report page
			window.location.href = `${host}/business/${editCompanyId}/reports/details`;
		}

		setIsGenerating(true);
		setIsLoading(true);

		//calculate grid points based on radius
		let gridPoints = 7;

		if (areaRadius > 5) {
			gridPoints = 9;
		} else if (areaRadius > 10) {
			gridPoints = 11;
		}

		let pointDistance = parseFloat(areaRadius * 2 / (gridPoints - 1));
		let companyId;
		let tempCompany;

		if (editCompanyId) {
			const companyResponse = await GetSalesCompanyDetails(editCompanyId);

			if (companyResponse) {
				companyId = companyResponse.id;
				tempCompany = companyResponse;

				if (gridData.lat && gridData.lat !== tempCompany.lat) {
					tempCompany.lat = gridData.lat;
					tempCompany.lng = gridData.lng;

					await UpdateSalesCompany(companyId, tempCompany);
				}
			}
		}

		if (!tempCompany) {
			let email =
				selectedBusiness.name.toLowerCase().replace(/ /g, '').replace(/[^\w-]+/g, '') + '@realwebsite.com';

			//first create company if it doesn't exist
			let companyData = {
				...selectedBusiness,
				userId            : selectedBusiness.salesUserId ? selectedBusiness.salesUserId : user.id,
				email             : email,
				phone             : selectedBusiness.formatted_phone_number,
				googleCid         : selectedBusiness.cid,
				googlePlaceId     : selectedBusiness.place_id,
				googleBusinessUrl : selectedBusiness.googleBusinessUrl
					? selectedBusiness.googleBusinessUrl
					: selectedBusiness.cid ? 'https://maps.google.com/?cid=' + selectedBusiness.cid : '',
				status            : 'Active'
			};

			const companyResponse = await CreateSalesCompany(companyData);

			if (!companyResponse.success) {
				if (
					companyResponse.data &&
					companyResponse.message.indexOf('already exists') > -1 &&
					companyResponse.data.userId === user.id
				) {
					companyId = companyResponse.data.id;
					tempCompany = companyResponse.data;

					//update lat and lng if necessary
					if (selectedBusiness && selectedBusiness.lat !== tempCompany.lat) {
						tempCompany.lat = selectedBusiness.lat;
						tempCompany.lng = selectedBusiness.lng;

						const updateCompanyResponse = await UpdateSalesCompany(companyId, tempCompany);

						if (!updateCompanyResponse.success) {
							console.log('Company lat/lng not updated. Reason: ' + updateCompanyResponse.message);
						}
					}
				}
			} else {
				tempCompany = companyResponse.data;
				companyId = companyResponse.data.id;
			}
		}

		if (tempCompany) {
			RemoveSalesBusiness();

			//create report
			let reportData = {
				salesUserId   : user && user.isSalesUser ? user.id : null,
				campaignId    : null,
				keywords      : keywordsArrayForReport,
				lat           : tempCompany.lat,
				lng           : tempCompany.lng,
				googlePlaceId : tempCompany.googlePlaceId,
				gridPoints    : gridPoints,
				pointDistance : pointDistance,
				gridApiType   : 'dseo'
			};

			const reportResponse = await GenerateSEOReportDataGrid(reportData);

			if (reportResponse.success) {
				let tempReport = reportResponse;

				let reportShortName = moment().format('MM-DD-YY-HH-mm');

				let keywordIndex = 0;

				//finally, create or update task for every keyword
				for (const keyword of keywordsArrayForReport) {
					let gridResponseData = tempReport.gridResponse.data.filter(
						(k) => k.keyword.trim().toLowerCase() === keyword.trim().toLowerCase()
					);

					keywordIndex++;

					let taskData = {
						salesCompanyId            : tempCompany.id,
						keyword                   : keyword,
						lat                       : tempCompany.lat,
						lng                       : tempCompany.lng,
						gridPoints                : gridPoints,
						gridPointsDistanceInMiles : pointDistance,
						gridRadius                : areaRadius,
						reportName                : reportShortName,
						orderIndex                : keywordIndex,
						content                   : JSON.stringify({
							googlePlaceId : tempCompany.googlePlaceId,
							gridResponse  : {
								lat              : tempCompany.lat,
								lng              : tempCompany.lng,
								horizontalPoints : gridPoints,
								verticalPoints   : gridPoints,
								data             : gridResponseData
							}
						})
					};

					let isTaskEdit = false;

					if (editCompanyId) {
						//update existing task with new grid report data
						let currentTask;

						if (reportTasks && reportTasks.length > 0) {
							currentTask = reportTasks.find(
								(x) => x.keyword.trim().toLowerCase() === keyword.trim().toLowerCase()
							);
						}

						if (currentTask && currentTask.id) {
							isTaskEdit = true;
							taskData.id = currentTask.id;
						} else {
							//task keyword not found, create new
							taskData.id = createGuid();
						}
					} else {
						//create new task
						taskData.id = createGuid();
					}

					let taskResponse;

					if (isTaskEdit) {
						taskResponse = await UpdateSalesCompanyTask(taskData.id, taskData);
					} else {
						taskResponse = await CreateSalesCompanyTask(taskData);
					}

					if (taskResponse.success) {
					}
				}

				//finally, we need to set order index for all tasks based on keywords order in the list
				let tasks = await GetSalesCompanyTasks(tempCompany.id);

				if (tasks && tasks.length > 0) {
					tasks.forEach((task) => {
						let keywordIndexFound = keywords
							.split('\n')
							.findIndex((x) => x.toLowerCase() === task.keyword.trim().toLowerCase());

						if (keywordIndexFound > -1) {
							task.orderIndex = keywordIndexFound + 1;
						}
					});

					//update indexes
					await UpdateSalesCompanyTaskIndexes(tasks);
				}

				window.location.href = `${host}/business/${tempCompany.id}/reports/details`;
			} else {
				toast.error(reportResponse.message);
				console.error(reportResponse);
				setIsGenerating(false);
				setIsLoading(false);
			}
		} else {
			setIsGenerating(false);
			setIsLoading(false);
		}
		//}
		//}
	};

	const handleSelectReportTask = async (task) => {
		setIsLoadingMap(true);
		setSelectedTask(task);
		await getReportTaskDetails(task.id, false, true);
	};

	const getReportShareLink = () => {
		var url = new URL(`${host}/public/reports/${selectedTask.id}`);

		copyToClipboard(url.href);

		setShowShareLinkCopiedMessage(true);

		setTimeout(function() {
			setShowShareLinkCopiedMessage(false);
		}, 1000);
	};

	const exportMapAsImage = () => {
		setIsLoadingMap(true);

		let companySlug = slugify(companyName);
		let date = moment().format('MMDDYYYYHHmm');
		let imageName = showGridReport
			? `exported-grid-${companySlug}-${slugify(selectedTask.keyword)}-${date}.png`
			: `exported-map-${companySlug}-${date}.png`;

		let $mapContainer = showGridReport
			? document.querySelector('#report-map-0')
			: document.querySelector('#report-map-preview');

		if ($mapContainer) {
			gridMapContainerRef.current.classList.add('no-transition');

			html2canvas($mapContainer, { useCORS: true })
				.then((canvas) => {
					var imgSrc = canvas.toDataURL('image/png');

					var link = document.createElement('a');
					if (typeof link.download !== 'string') {
						window.open(imgSrc);
					} else {
						link.href = imgSrc;
						link.download = imageName;
						link.click();
						//accountForFirefox(clickLink, link);
					}

					setIsLoadingMap(false);
				})
				.finally(() => {
					gridMapContainerRef.current.classList.remove('no-transition');
					setIsLoadingMap(false);
				});
		}
	};

	const handleCircleMove = (center) => {
		if (center) {
			if (editCompanyId) {
				setGridData({
					...gridData,
					lat : center.lat,
					lng : center.lng
				});
			} else {
				let tempBusiness = { ...selectedBusiness };

				tempBusiness.lat = center.lat;
				tempBusiness.lng = center.lng;

				setSelectedBusiness(tempBusiness);

				StoreSalesBusiness(tempBusiness);
			}
		}
	};

	const generateAuditData = async () => {
		if (!selectedIndustryCategory || selectedIndustryCategory.trim() === '') {
			toast.error('You have to select an industry first');
			return;
		}
		if (companyData && selectedTask) {
			setIsGeneratingAudit(true);

			let data = {
				companyId          : companyId,
				industryCategoryId : selectedIndustryCategory,
				businessPlaceId    : companyData.googlePlaceId,
				keywords           : selectedTask.keyword
			};

			//add max 3 competitors place Ids
			let competitorsPlaceIds = [];
			if (competitorsData && competitorsData.length > 0) {
				competitorsData.forEach((competitor) => {
					if (
						competitor.place_id &&
						!competitorsPlaceIds.includes(competitor.place_id) &&
						competitorsPlaceIds.length < 3
					) {
						competitorsPlaceIds.push(competitor.place_id);
					}
				});
			}

			data.competitorsPlaceIds = competitorsPlaceIds;

			let response = await GenerateReportData(data);

			if (response.success && response.data) {
				setAuditKeywordData(response.data);
			}

			setIsGeneratingAudit(false);
		}
	};

	const getAuditKeywordData = async (id, keyword) => {
		let response = await GetReportDataByCompanyId(id, keyword);

		if (response.success) {
			setAuditKeywordData(response.data);

			if (response.data && response.data.inputData) {
				setSelectedIndustryCategory(response.data.inputData.industryCategoryId);
			}
		} else {
			setAuditKeywordData();
			setSelectedIndustryCategory();
		}
	};

	const getCompetitorsData = async () => {
		setIsUpdatingAudit(true);

		if (selectedTaskGridData && selectedTaskGridData.gridResponse && selectedTaskGridData.gridResponse.data) {
			let tempCompetitorsData = [];

			//find competitor companies with most top rankings
			let selectedKeywordData = selectedTaskGridData.gridResponse.data.find(
				(x) => x.keyword === selectedTask.keyword
			);

			//we need to attach opposite rankings in order to find most top rankings
			//i.e for ranking 1 we set 21, for 2 is 20..... for 21 is 0, so it's (21-position)
			if (selectedKeywordData && selectedKeywordData.points) {
				selectedKeywordData.points.forEach((point) => {
					if (point.results && point.results.length) {
						point.results.forEach((pointResult) => {
							let existingItem = tempCompetitorsData.find((x) => x.place_id === pointResult.place_id);
							let position = pointResult.position || 0;

							if (existingItem) {
								existingItem.totalRating += 21 - position;

								if (position > 0 && position < 4) {
									existingItem.top3Count++;
								}
							} else {
								pointResult.totalRating = 21 - position;

								pointResult.top3Count = position > 0 && position < 4 ? 1 : 0;

								//skip current company results
								if (pointResult.place_id !== companyData.googlePlaceId) {
									tempCompetitorsData.push(pointResult);
								}
							}
						});
					}
				});
			}

			tempCompetitorsData = tempCompetitorsData.sort((a, b) => b.top3Count - a.top3Count);

			setCompetitorsData(tempCompetitorsData);

			let tempCompetitorsDataCopyString = '';

			tempCompetitorsData.forEach((competitor, index) => {
				if (competitor.top3Count && competitor.top3Count > 0) {
					tempCompetitorsDataCopyString += `${competitor.title} (${competitor.top3Count}), ${competitor.place_id}, ${competitor.data_cid}, ${competitor.website}\r\n`;
				}
			});

			setCompetitorsDataForCopy(tempCompetitorsDataCopyString);
		}

		setIsUpdatingAudit(false);
	};

	const handleCopyCompetitorsData = () => {
		copyToClipboard(competitorsDataForCopy);
		toast.success('Copied to clipboard!');
	};

	const printJsonNodeValue = (dataIndex, setting) => {
		let output = '';

		let nodeValue = setting.propValue;
		let subNodeValue = setting.propSubValue;
		let label = setting.label;

		if (auditKeywordData && auditKeywordData.audit) {
			let foundNodeValue = auditKeywordData.audit[dataIndex][setting.type];

			if (nodeValue) {
				foundNodeValue = auditKeywordData.audit[dataIndex][setting.type][nodeValue];
			}

			if (nodeValue && subNodeValue) {
				foundNodeValue = auditKeywordData.audit[dataIndex][setting.type][nodeValue][subNodeValue];
			}

			if (foundNodeValue) {
				if (label) {
					output += `<h4 class="json-output-title mb-3">${label}</h4>`;
				}

				if (Array.isArray(foundNodeValue)) {
					foundNodeValue.map((value, index) => {
						if (typeof value === 'object') {
							output +=
								'<div class="json-output-array-item mb-2 py-2 border-bottom" key="' + index + '">';
							output += outputObjectAsHtml(value, index, 0);
							output += '</div>';
						} else {
							output += outputValueAsHtml(value, index);
						}
					});
				} else if (typeof foundNodeValue === 'object') {
					output += outputObjectAsHtml(foundNodeValue, null, 0);
				} else {
					output += outputValueAsHtml(foundNodeValue);
				}
			}
		}

		setJsonOutput(output);
		toggleJsonOutputDialog();

		return output;
	};

	const outputObjectAsHtml = (objectValue, arrayIndex = null, nestingLevel = 0) => {
		let output = '';

		// if (nestingLevel === 1 && typeof objectValue === 'object') {
		// 	// output += `<div class="form-label json-output-object-item-value" dangerouslySetInnerHTML="${objectValue}"></div>`;
		// 	output += `<div class="form-label json-output-object-item-value">${objectValue}</div>`;
		// 	return output;
		// }

		Object.keys(objectValue).map((key, objectIndex) => {
			let parsedItemValue;

			if (objectValue[key]) {
				if (typeof objectValue[key] === 'object') {
					//parsedItemValue += objectValue[key];

					output += outputObjectAsHtml(objectValue[key], null, nestingLevel++);

					// for (var itemValueKey in objectValue) {
					// 	if (objectValue.hasOwnProperty(itemValueKey)) {
					// 		parsedItemValue +=
					// 			objectValue[itemValueKey] + (key === 'keywordItems' ? '\n' : ', ');
					// 	}
					// }
					// if (parsedItemValue && parsedItemValue.length > 2) {
					// 	parsedItemValue = parsedItemValue.substring(0, parsedItemValue.length - 2);
					// }
				} else {
					parsedItemValue = objectValue[key];
				}

				if (parsedItemValue === true) {
					parsedItemValue = 'Yes';
				} else if (parsedItemValue === false) {
					parsedItemValue = 'No';
				}

				output += `<div class="col-12 json-output-object-item" key="${objectIndex}">`;
				output += `<label class="form-label json-output-object-item-label" title="${key}"><strong>${arrayIndex !==
					null && objectIndex === 0
					? `<span>${arrayIndex + 1}. </span>`
					: ''}<span>${key}:</span></strong></label>`;

				if (key === 'keywordItems') {
					output += `<textarea class="form-control json-output-object-item-value" rows=6 value="${parsedItemValue}" readOnly />`;
				} else {
					if (typeof parsedItemValue === 'string') {
						if (parsedItemValue.indexOf('https://') > -1 || parsedItemValue.indexOf('http://') > -1) {
							output += `<a class="btn btn-link p-0 json-output-object-item-value" title="${parsedItemValue}" target="_blank" href="${parsedItemValue}">${parsedItemValue}</a>`;
						} else {
							output += `<label class="form-label json-output-object-item-value" title="${parsedItemValue}">${parsedItemValue}</label>`;
						}
					} else {
						//output += `<label class="form-label json-output-object-item-value" title="${parsedItemValue}">${parsedItemValue}</label>`;

						if (typeof parsedItemValue === 'object') {
							output += `<div class="form-label json-output-object-item-value" title="${parsedItemValue}" dangerouslySetInnerHTML="${parsedItemValue}"></div>`;
							//output += `<label class="form-label json-output-object-item-value">${parsedItemValue}</label>`;
						} else {
							output += `<label class="form-label json-output-object-item-value" title="${parsedItemValue}">${parsedItemValue}</label>`;
						}
					}
				}

				output += `</div>`;
			}
		});

		return output;
	};

	const outputValueAsHtml = (value, arrayIndex = null) => {
		let output = '';
		let ordinal = arrayIndex !== null ? `<span>${arrayIndex + 1}. </span>` : '';

		if (typeof value === 'string') {
			if (value.indexOf('https://') > -1 || value.indexOf('http://') > -1) {
				output += `<a class="btn btn-link py-0 json-output-object-item-value" title="${value}" target="_blank" href="${value}">${ordinal}${value}</a>`;
			} else {
				output += `<label class="form-label json-output-object-item-value" title="${value}">${ordinal}${value}</label>`;
			}
		} else {
			output += `<label class="form-label json-output-object-item-value" title="${value}">${ordinal}${value}</label>`;
		}

		return output;
	};

	const toggleJsonOutputDialog = async (e) => {
		setShowJsonOutputDialog(!showJsonOutputDialog);

		if (showJsonOutputDialog) {
			setJsonOutput();
		}

		if (e) e.preventDefault();
	};

	return (
		<div className="app-container">
			<div className="grid-tool-left-container">
				{showGridReport ? (
					<div className="row p-3">
						{isLoading ? (
							<div className="text-center">
								<Spinner />
							</div>
						) : (
							<Fragment>
								{companyName && (
									<div className="company-name text-truncate">
										<span>{companyName}</span>
									</div>
								)}

								{dateCreated && (
									<div className="created-date mb-3">
										<Fragment>
											<span>Date Created:</span>
											<span className="ms-2">
												{moment(dateCreated).format('MM/DD/YYYY [@] hh:mm A [EST]')}
											</span>
										</Fragment>
									</div>
								)}

								{companyId && (
									<div className="mb-4">
										<button
											className="btn btn-link p-0 text-decoration-underline"
											onClick={() => navigate(`/grid-report-tool/${companyId}`)}
										>
											Change Keywords or Grid Size
										</button>
									</div>
								)}

								<div>
									{reportTasks &&
										reportTasks.length > 0 &&
										reportTasks.map((task, index) => (
											<div key={index} className="mb-2">
												<button
													className={`btn btn${selectedTask && selectedTask.id === task.id
														? ''
														: '-outline'}-primary btn-lg w-100 `}
													onClick={() => handleSelectReportTask(task)}
													disabled={isGenerating || isLoadingMap || reportId}
												>
													{task.keyword}
												</button>
											</div>
										))}
								</div>
							</Fragment>
						)}
					</div>
				) : (
					<Fragment>
						{isLoading ? (
							<div className="text-center">
								<Spinner />
							</div>
						) : (
							<div className="row p-3">
								{companyName && (
									<div className="company-name text-truncate">
										<span>{companyName}</span>
									</div>
								)}

								<form className="mt-3">
									<div className="col-12 mb-3">
										<label className="form-label">Service Area Radius</label>
										<div className="row">
											<div className="col-9 pe-0">
												<input
													type="range"
													name="areaRadius"
													className="form-range"
													value={areaRadius || ''}
													onChange={handleChange}
													min={1}
													max={15}
													step={0.5}
													disabled={isGenerating || isLoadingMap}
												/>
											</div>
											<div className="col-3 text-end">
												<span className="area-radius-value">{`${areaRadius || ''} miles`}</span>
											</div>
										</div>
									</div>
									<div className="col-12 mb-3">
										<label className="form-label">Keywords: (one per line, up to 5 terms)</label>
										<textarea
											name="keywords"
											className="form-control"
											value={keywords || ''}
											onChange={handleChange}
											disabled={isGenerating || isLoadingMap}
											rows={5}
										/>
									</div>
									<div className="col-12 text-center">
										<button
											className="btn btn-primary"
											onClick={(e) => generateReport(e)}
											disabled={isGenerating || isLoadingMap}
										>
											{isGenerating ? (
												<Fragment>
													<span className="spinner-border m-0 me-2" />
													<span>Generating Report...</span>
												</Fragment>
											) : (
												<span>Continue</span>
											)}
										</button>
									</div>
								</form>
							</div>
						)}
					</Fragment>
				)}

				{reportTasks &&
				reportTasks.length > 0 &&
				!isLoading && (
					<div className="grid-tool-buttons-container">
						<button
							className="btn btn-link p-0"
							onClick={() => getReportShareLink()}
							disabled={isGenerating || isLoadingMap}
						>
							<span>
								{showShareLinkCopiedMessage ? (
									<span className="bg-success text-white py-1 px-3 rounded">Copied!</span>
								) : (
									'Get Share Link'
								)}
							</span>
						</button>
						<span className="mx-2">|</span>
						<button
							className="btn btn-link p-0"
							onClick={exportMapAsImage}
							disabled={isGenerating || isLoadingMap}
						>
							Export As Image
						</button>

						<div className="mt-3">
							<Link className="btn btn-outline-primary" to="/reports">
								<i className="fa-solid fa-arrow-left-long me-2" />Reports
							</Link>
						</div>
					</div>
				)}
			</div>

			<div className="main-content-inner-container grid-tool-content-container p-2 rounded-0">
				<div className="map-wrapper position-relative" ref={gridMapContainerRef}>
					<Fragment>
						{showGridReport ? (
							<Fragment>
								{(!gridData || isGenerating || isLoadingMap) && (
									<div className="loading-map">
										<Spinner />
									</div>
								)}

								<ul className="nav nav-tabs" id="tabReport" role="tablist">
									<li className="nav-item" role="presentation" key="grid-report">
										<button
											className="nav-link active"
											id="grid-report-tab"
											data-bs-toggle="tab"
											data-bs-target="#grid-report-tab-pane"
											type="button"
											role="tab"
											aria-controls="grid-report-tab-pane"
											aria-selected="true"
										>
											Grid
										</button>
									</li>
									<li className="nav-item" role="presentation" key="competitors">
										<button
											className="nav-link"
											id="competitors-tab"
											data-bs-toggle="tab"
											data-bs-target="#competitors-tab-pane"
											type="button"
											role="tab"
											aria-controls="competitors-tab-pane"
											aria-selected="true"
										>
											Competitors
										</button>
									</li>
									<li className="nav-item" role="presentation" key="audit-report">
										<button
											className="nav-link"
											id="audit-report-tab"
											data-bs-toggle="tab"
											data-bs-target="#audit-report-tab-pane"
											type="button"
											role="tab"
											aria-controls="audit-report-tab-pane"
											aria-selected="true"
										>
											Audit
										</button>
									</li>
								</ul>
								<div className="tab-content border" id="tabReportContent">
									<div
										className="tab-pane fade show active"
										id="grid-report-tab-pane"
										role="tabpanel"
										aria-labelledby="grid-report-tab"
										tabIndex="0"
									>
										{gridData && (
											<Fragment>
												<GridMapV5
													key="map-0"
													mapIdValue="report-map-0"
													gridData={gridData}
													placeId={gridData.googlePlaceId}
													isFullHeight={true}
													reportSettings={reportSettings}
												/>
											</Fragment>
										)}
									</div>
									<div
										className="tab-pane fade"
										id="competitors-tab-pane"
										role="tabpanel"
										aria-labelledby="competitors-tab"
										tabIndex="0"
									>
										<div className="competitors-data-container p-3">
											{/* <button
												className="btn btn-primary mb-3"
												onClick={getCompetitorsData}
												disabled={isUpdatingAudit}
											>
												{isUpdatingAudit ? (
													<Fragment>
														<span className="spinner-border m-0 me-2" />
														<span>Getting Data...</span>
													</Fragment>
												) : (
													<span>Get Competitors</span>
												)}
											</button> */}

											{competitorsData &&
											competitorsData.length > 0 && (
												<button
													className="btn btn-outline-primary mb-3"
													onClick={handleCopyCompetitorsData}
												>
													Copy Data
												</button>
											)}

											{competitorsData &&
											competitorsData.length > 0 && (
												<div className="competitors-wrapper">
													{competitorsData.map((competitor, index) => (
														<Fragment key={index}>
															{competitor.top3Count ? (
																<div className="competitor-item mb-3">
																	<div className="competitor-name">
																		{competitor.title || ''}
																	</div>
																	<div className="competitor-top3-count">
																		<span className="competitor-label">
																			Top 3 Rankings:
																		</span>
																		<span className="competitor-value">
																			{competitor.top3Count || ''}
																		</span>
																	</div>
																	<div className="competitor-detail">
																		<span className="competitor-label">
																			Place Id:
																		</span>
																		<span className="competitor-value">
																			{competitor.place_id || ''}
																		</span>
																	</div>
																	<div className="competitor-detail">
																		<span className="competitor-label">Cid:</span>
																		<span className="competitor-value">
																			{competitor.data_cid || ''}
																		</span>
																	</div>
																	<div className="competitor-detail">
																		<span className="competitor-label">
																			Website:
																		</span>
																		<span className="competitor-value">
																			{competitor.website || ''}
																		</span>
																	</div>
																</div>
															) : (
																<Fragment />
															)}
														</Fragment>
													))}
												</div>
											)}
										</div>
									</div>
									<div
										className="tab-pane fade"
										id="audit-report-tab-pane"
										role="tabpanel"
										aria-labelledby="audit-report-tab"
										tabIndex="0"
									>
										<div className="audit-data-container p-3">
											<div className="row align-items-center mb-3">
												<div className="col-auto">
													<label className="form-label mb-0">Industry:</label>
												</div>
												<div className="col-auto">
													<select
														className="form-select"
														onChange={(e) => setSelectedIndustryCategory(e.target.value)}
														value={selectedIndustryCategory || ''}
													>
														<option value="">- Select Industry -</option>
														{adminSettings &&
														adminSettings.industryCategories && (
															<Fragment>
																{adminSettings.industryCategories.map((cat, index) => (
																	<option value={cat.categoryGuid} key={index}>
																		{cat.categoryName}
																	</option>
																))}
															</Fragment>
														)}
													</select>
												</div>
												<div className="col-auto">
													<button
														className="btn btn-primary"
														onClick={generateAuditData}
														disabled={isGeneratingAudit}
													>
														{isGeneratingAudit ? (
															<Fragment>
																<span className="spinner-border m-0 me-2" />
																<span>Generating...</span>
															</Fragment>
														) : (
															<span>Generate Audit Data</span>
														)}
													</button>
												</div>
											</div>

											<div className="audit-table-wrapper border">
												<table className="items-list audit-table table table-responsive">
													<thead>
														<tr>
															<th scope="col" className="description">
																Average Gird Rank:
															</th>
															<th className="business" scope="col">
																-
															</th>
															<th className="business competitor" scope="col">
																-
															</th>
															<th className="business competitor" scope="col">
																-
															</th>
															<th className="business competitor" scope="col">
																-
															</th>
														</tr>
														<tr>
															<th scope="col" className="description">
																Description:
															</th>
															<th className="business" scope="col">
																{companyData && companyData.googleBusinessUrl ? (
																	<a
																		className="btn btn-link p-0"
																		href={companyData.googleBusinessUrl}
																		target="_blank"
																		title={companyData.name || ''}
																	>
																		<span>Your Business</span>
																		<i className="fa-solid fa-arrow-up-right-from-square ms-2" />
																	</a>
																) : (
																	'Your Business'
																)}
															</th>
															<th className="business competitor" scope="col">
																{competitorsData &&
																competitorsData.length > 0 &&
																competitorsData[0].data_cid ? (
																	<a
																		className="btn btn-link p-0"
																		href={`https://maps.google.com/?cid=${competitorsData[0]
																			.data_cid}`}
																		target="_blank"
																		title={competitorsData[0].title || ''}
																	>
																		<span>Competitor 1</span>
																		<i className="fa-solid fa-arrow-up-right-from-square ms-2" />
																	</a>
																) : (
																	'Competitor 1'
																)}
															</th>
															<th className="business competitor" scope="col">
																{competitorsData &&
																competitorsData.length > 1 &&
																competitorsData[1].data_cid ? (
																	<a
																		className="btn btn-link p-0"
																		href={`https://maps.google.com/?cid=${competitorsData[1]
																			.data_cid}`}
																		target="_blank"
																		title={competitorsData[1].title || ''}
																	>
																		<span>Competitor 2</span>
																		<i className="fa-solid fa-arrow-up-right-from-square ms-2" />
																	</a>
																) : (
																	'Competitor 2'
																)}
															</th>
															<th className="business competitor" scope="col">
																{competitorsData &&
																competitorsData.length > 2 &&
																competitorsData[2].data_cid ? (
																	<a
																		className="btn btn-link p-0"
																		href={`https://maps.google.com/?cid=${competitorsData[2]
																			.data_cid}`}
																		target="_blank"
																		title={competitorsData[2].title || ''}
																	>
																		<span>Competitor 3</span>
																		<i className="fa-solid fa-arrow-up-right-from-square ms-2" />
																	</a>
																) : (
																	'Competitor 3'
																)}
															</th>
														</tr>
													</thead>
													{auditKeywordData &&
													auditKeywordData.audit &&
													auditKeywordData.audit.length > 0 ? (
														<tbody>
															{/* Profile Settings */}
															{settings.map((setting) => (
																<tr key={setting.label}>
																	<td className="description">{setting.label}</td>
																	{auditKeywordData.audit.map((cat, index) => {
																		let profile = cat[setting.type];
																		let propValue = profile
																			? profile[setting.propValue]
																			: null;
																		let printPropValue =
																			profile && setting.printPropValue
																				? propValue[setting.printPropValue]
																				: propValue;
																		let propSubValue =
																			profile && propValue
																				? propValue[setting.propSubValue]
																				: null;
																		let isArray = propSubValue
																			? Array.isArray(propSubValue)
																			: printPropValue &&
																				Array.isArray(printPropValue);
																		let isObject = propSubValue
																			? typeof propSubValue === 'object' &&
																				!isArray
																			: printPropValue &&
																				typeof printPropValue === 'object' &&
																				!isArray;
																		let showNumber = setting.responseType === 'int';

																		return (
																			<td
																				key={index}
																				className={`business ${cat ===
																				'business'
																					? ''
																					: 'competitor'}`}
																			>
																				{profile && printPropValue ? (
																					<Fragment>
																						{isArray ? (
																							<Fragment>
																								{printPropValue.length >
																								0 ? (
																									<button
																										className="btn btn-link p-0 btn-json-output"
																										onClick={() =>
																											printJsonNodeValue(
																												index,
																												setting
																											)}
																									>
																										<span>
																											{showNumber ? (
																												printPropValue.length
																											) : (
																												'Yes'
																											)}
																										</span>
																									</button>
																								) : (
																									<Fragment>
																										{propSubValue.length >
																										0 ? (
																											<button
																												className="btn btn-link p-0 btn-json-output"
																												onClick={() =>
																													printJsonNodeValue(
																														index,
																														setting
																													)}
																											>
																												<span>
																													{showNumber ? (
																														propSubValue.length
																													) : (
																														'Yes'
																													)}
																												</span>
																											</button>
																										) : (
																											<span className="json-output-no-value">
																												{showNumber ? (
																													'0'
																												) : (
																													'No'
																												)}
																											</span>
																										)}
																									</Fragment>
																								)}
																							</Fragment>
																						) : (
																							<Fragment>
																								{isObject ? (
																									<Fragment>
																										{propSubValue ? (
																											<Fragment>
																												{
																													<button
																														className="btn btn-link p-0 btn-json-output"
																														onClick={() =>
																															printJsonNodeValue(
																																index,
																																setting
																															)}
																													>
																														<span
																														>
																															{showNumber ? (
																																propSubValue
																															) : (
																																'Yes'
																															)}
																														</span>
																													</button>
																												}
																											</Fragment>
																										) : (
																											<span className="json-output-no-value">
																												{showNumber ? (
																													'0'
																												) : (
																													'No'
																												)}
																											</span>
																										)}
																									</Fragment>
																								) : (
																									<button
																										className="btn btn-link p-0 btn-json-output"
																										onClick={() =>
																											printJsonNodeValue(
																												index,
																												setting
																											)}
																									>
																										<span>
																											{showNumber ? (
																												printPropValue
																											) : (
																												'Yes'
																											)}
																										</span>
																									</button>
																								)}
																							</Fragment>
																						)}
																					</Fragment>
																				) : (
																					<span className="json-output-no-value">
																						{showNumber ? '0' : 'No'}
																					</span>
																				)}
																			</td>
																		);
																	})}
																</tr>
															))}

															<tr>
																<td
																	className="border border-secondary border-2 p-0"
																	colSpan={5}
																/>
															</tr>
															<tr>
																<td colSpan={5} className="separator-row">
																	<strong>Keywords In:</strong>
																</td>
															</tr>

															{/* Keywords In Settings */}
															{kwSettings.map((setting) => (
																<tr key={setting.label}>
																	<td className="description">{setting.label}</td>
																	{auditKeywordData.audit.map((cat, index) => {
																		let profile = cat[setting.type];
																		let propValue = profile
																			? profile[setting.propValue]
																			: null;
																		let printPropValue =
																			profile && setting.printPropValue
																				? propValue[setting.printPropValue]
																				: propValue;
																		let propSubValue =
																			profile && propValue
																				? propValue[setting.propSubValue]
																				: null;
																		let isArray = propSubValue
																			? Array.isArray(propSubValue)
																			: printPropValue &&
																				Array.isArray(printPropValue);
																		let isObject = propSubValue
																			? typeof propSubValue === 'object' &&
																				!isArray
																			: printPropValue &&
																				typeof printPropValue === 'object' &&
																				!isArray;
																		let showNumber = setting.responseType === 'int';

																		return (
																			<td
																				key={index}
																				className={`business ${cat ===
																				'business'
																					? ''
																					: 'competitor'}`}
																			>
																				{profile && printPropValue ? (
																					<Fragment>
																						{isArray ? (
																							<Fragment>
																								{printPropValue.length >
																								0 ? (
																									<button
																										className="btn btn-link p-0 btn-json-output"
																										onClick={() =>
																											printJsonNodeValue(
																												index,
																												setting
																											)}
																									>
																										<span>
																											{showNumber ? (
																												printPropValue.length
																											) : (
																												'Yes'
																											)}
																										</span>
																									</button>
																								) : (
																									<Fragment>
																										{propSubValue.length >
																										0 ? (
																											<button
																												className="btn btn-link p-0 btn-json-output"
																												onClick={() =>
																													printJsonNodeValue(
																														index,
																														setting
																													)}
																											>
																												<span>
																													{showNumber ? (
																														propSubValue.length
																													) : (
																														'Yes'
																													)}
																												</span>
																											</button>
																										) : (
																											<span className="json-output-no-value">
																												{showNumber ? (
																													'0'
																												) : (
																													'No'
																												)}
																											</span>
																										)}
																									</Fragment>
																								)}
																							</Fragment>
																						) : (
																							<Fragment>
																								{isObject ? (
																									<Fragment>
																										{propSubValue ? (
																											<Fragment>
																												{
																													<button
																														className="btn btn-link p-0 btn-json-output"
																														onClick={() =>
																															printJsonNodeValue(
																																index,
																																setting
																															)}
																													>
																														<span
																														>
																															{showNumber ? (
																																propSubValue
																															) : (
																																'Yes'
																															)}
																														</span>
																													</button>
																												}
																											</Fragment>
																										) : (
																											<span className="json-output-no-value">
																												{showNumber ? (
																													'0'
																												) : (
																													'No'
																												)}
																											</span>
																										)}
																									</Fragment>
																								) : (
																									<button
																										className="btn btn-link p-0 btn-json-output"
																										onClick={() =>
																											printJsonNodeValue(
																												index,
																												setting
																											)}
																									>
																										<span>
																											{showNumber ? (
																												printPropValue
																											) : (
																												'Yes'
																											)}
																										</span>
																									</button>
																								)}
																							</Fragment>
																						)}
																					</Fragment>
																				) : (
																					<span className="json-output-no-value">
																						{showNumber ? '0' : 'No'}
																					</span>
																				)}
																			</td>
																		);
																	})}
																</tr>
															))}

															{/* Power Content */}
															{auditKeywordData.audit[0] &&
															auditKeywordData.audit[0].powerContent &&
															auditKeywordData.audit[0].powerContent.length > 0 && (
																<Fragment>
																	<tr>
																		<td colSpan={5} className="separator-row">
																			Power Content
																		</td>
																	</tr>
																	{auditKeywordData.audit[0].powerContent.map(
																		(pc, pcIndex) => {
																			return (
																				<tr key={pcIndex}>
																					<td className="description">
																						{pc.website.replace(
																							'https://',
																							''
																						)}
																					</td>
																					{auditKeywordData.audit.map(
																						(cat, index) => {
																							let pcItem =
																								cat.powerContent &&
																								cat.powerContent
																									.length > 0
																									? cat.powerContent.find(
																											(x) =>
																												x.website ===
																												pc.website
																										)
																									: null;

																							return (
																								<td
																									key={index}
																									className={`business ${cat ===
																									'business'
																										? ''
																										: 'competitor'}`}
																								>
																									{pcItem &&
																									pcItem.total ? (
																										<Fragment>
																											<button className="btn btn-link p-0 btn-json-output">
																												<span>
																													{
																														pcItem.total
																													}
																												</span>
																											</button>
																										</Fragment>
																									) : (
																										<span className="json-output-no-value">
																											0
																										</span>
																									)}
																								</td>
																							);
																						}
																					)}
																				</tr>
																			);
																		}
																	)}
																</Fragment>
															)}

															<tr>
																<td
																	className="border border-secondary border-2 p-0"
																	colSpan={5}
																/>
															</tr>

															{/* Domain Age */}
															<tr>
																<td className="description">Domain Age</td>
																{auditKeywordData.audit.map((cat, index) => {
																	let dateNow = moment(new Date()); //'DD/MM/YYYY HH:mm:ss'
																	let elapsedYears =
																		cat.whois && cat.whois.CreatedDate
																			? dateNow.diff(
																					cat.whois.CreatedDate,
																					'years'
																				)
																			: '';

																	return (
																		<td key={index} className="business">
																			{elapsedYears ? (
																				`${elapsedYears} ${elapsedYears === 1
																					? 'Year'
																					: 'Years'}`
																			) : (
																				''
																			)}
																		</td>
																	);
																})}
															</tr>

															{/* Total Indexed Pages */}
															<tr>
																<td className="description">Total Indexed Pages</td>
																{auditKeywordData.audit.map((cat, index) => (
																	<td
																		key={index}
																		className={`business ${cat === 'business'
																			? ''
																			: 'competitor'}`}
																	>
																		{cat.indexedPages && cat.indexedPages.total ? (
																			<button
																				className="btn btn-link p-0 btn-json-output"
																				title={cat.indexedPages.total}
																			>
																				<span>{cat.indexedPages.total}</span>
																			</button>
																		) : (
																			<span className="json-output-no-value">
																				0
																			</span>
																		)}
																	</td>
																))}
															</tr>

															{/* Backlinks */}
															<tr>
																<td className="description">Backlinks</td>
																{auditKeywordData.audit.map((cat, index) => (
																	<td
																		key={index}
																		className={`business ${cat === 'business'
																			? ''
																			: 'competitor'}`}
																	>
																		{cat.backlinks && cat.backlinks.total ? (
																			<button
																				className="btn btn-link p-0 btn-json-output"
																				title={cat.backlinks.total}
																			>
																				<span>{cat.backlinks.total}</span>
																			</button>
																		) : (
																			<span className="json-output-no-value">
																				0
																			</span>
																		)}
																	</td>
																))}
															</tr>

															{/* Total Citations */}
															<tr>
																<td className="description">Total Citations</td>
																{auditKeywordData.audit.map((cat, index) => (
																	<td
																		key={index}
																		className={`business ${cat === 'business'
																			? ''
																			: 'competitor'}`}
																	>
																		{cat.citations && cat.citations.total ? (
																			<Fragment>
																				<button className="btn btn-link p-0 btn-json-output">
																					<span>{cat.citations.total}</span>
																				</button>
																			</Fragment>
																		) : (
																			''
																		)}
																	</td>
																))}
															</tr>

															{/* Power Citations */}
															{auditKeywordData.audit[0] &&
															auditKeywordData.audit[0].powerCitations &&
															auditKeywordData.audit[0].powerCitations.length > 0 && (
																<Fragment>
																	<tr>
																		<td colSpan={5} className="separator-row">
																			Power Citations
																		</td>
																	</tr>
																	{auditKeywordData.audit[0].powerCitations.map(
																		(pc, pcIndex) => {
																			return (
																				<tr key={pcIndex}>
																					<td className="description">
																						{pc.website.replace(
																							'https://',
																							''
																						)}
																					</td>
																					{auditKeywordData.audit.map(
																						(cat, index) => {
																							let pcItem =
																								cat.powerCitations &&
																								cat.powerCitations
																									.length > 0
																									? cat.powerCitations.find(
																											(x) =>
																												x.website ===
																												pc.website
																										)
																									: null;

																							return (
																								<td
																									key={index}
																									className={`business ${cat ===
																									'business'
																										? ''
																										: 'competitor'}`}
																								>
																									{pcItem &&
																									pcItem.total ? (
																										<Fragment>
																											<button
																												className="btn btn-link p-0 btn-json-output"
																												title={
																													pcItem.total
																												}
																											>
																												<span>
																													Yes
																												</span>
																											</button>
																										</Fragment>
																									) : (
																										<span className="json-output-no-value">
																											No
																										</span>
																									)}
																								</td>
																							);
																						}
																					)}
																				</tr>
																			);
																		}
																	)}
																</Fragment>
															)}

															<tr>
																<td
																	className="border border-secondary border-2 p-0"
																	colSpan={5}
																/>
															</tr>

															{/* Review Score */}
															<tr>
																<td className="separator-row">Review Score</td>
																{auditKeywordData.audit.map((cat, index) => (
																	<td key={index} className="separator-row" />
																))}
															</tr>

															{/* Total Reviews */}
															<tr>
																<td className="description">Total Reviews</td>
																{auditKeywordData.audit.map((cat, index) => (
																	<td key={index} className="business">
																		{cat.reviews && cat.reviews.total ? (
																			<button
																				className="btn btn-link p-0 btn-json-output"
																				title={cat.reviews.total}
																			>
																				<span>{cat.reviews.total}</span>
																			</button>
																		) : (
																			<span className="json-output-no-value">
																				0
																			</span>
																		)}
																	</td>
																))}
															</tr>

															{/* Average Rating */}
															<tr>
																<td className="description">Average Rating</td>
																{auditKeywordData.audit.map((cat, index) => (
																	<td key={index} className="business">
																		{cat.profile &&
																		cat.profile.Rating &&
																		cat.profile.Rating.Value ? (
																			<button
																				className="btn btn-link p-0 btn-json-output"
																				title={cat.profile.Rating.Value}
																			>
																				<span>{cat.profile.Rating.Value}</span>
																			</button>
																		) : (
																			<span className="json-output-no-value">
																				0
																			</span>
																		)}
																	</td>
																))}
															</tr>

															{/* Reviews Last 30 Days */}
															<tr>
																<td colSpan={5} className="separator-row">
																	<strong>Last 30 Days</strong>
																</td>
															</tr>
															{reviewsLast30DaysSettings.map((setting) => (
																<tr key={setting.label}>
																	<td className="description">{setting.label}</td>
																	{auditKeywordData.audit.map((cat, index) => {
																		let propValue =
																			cat.reviews &&
																			cat.reviews.last30Days &&
																			cat.reviews.last30Days[setting.propValue]
																				? cat.reviews.last30Days[
																						setting.propValue
																					]
																				: null;
																		let printPropValue = propValue;
																		let isArray =
																			printPropValue &&
																			Array.isArray(printPropValue);
																		let isObject =
																			printPropValue &&
																			typeof printPropValue === 'object' &&
																			!isArray;
																		let showNumber = setting.responseType === 'int';

																		if (
																			typeof printPropValue == 'number' &&
																			!isNaN(printPropValue) &&
																			!Number.isInteger(printPropValue)
																		) {
																			printPropValue = parseFloat(
																				printPropValue
																			).toFixed(2);
																		}

																		return (
																			<td
																				key={index}
																				className={`business ${cat ===
																				'business'
																					? ''
																					: 'competitor'}`}
																			>
																				{printPropValue ? (
																					<Fragment>
																						{isArray ? (
																							<Fragment>
																								{printPropValue.length >
																								0 ? (
																									<button
																										className="btn btn-link p-0 btn-json-output"
																										onClick={() =>
																											printJsonNodeValue(
																												index,
																												setting
																											)}
																									>
																										<span>
																											{showNumber ? (
																												printPropValue.length
																											) : (
																												'Yes'
																											)}
																										</span>
																									</button>
																								) : (
																									<Fragment />
																								)}
																							</Fragment>
																						) : (
																							<Fragment>
																								{isObject ? (
																									<Fragment />
																								) : (
																									<button
																										className="btn btn-link p-0 btn-json-output"
																										onClick={() =>
																											printJsonNodeValue(
																												index,
																												setting
																											)}
																									>
																										<span>
																											{showNumber ? (
																												printPropValue
																											) : (
																												'Yes'
																											)}
																										</span>
																									</button>
																								)}
																							</Fragment>
																						)}
																					</Fragment>
																				) : (
																					<span className="json-output-no-value">
																						{showNumber ? (
																							0
																						) : (
																							<span className="json-output-no-value">
																								No
																							</span>
																						)}
																					</span>
																				)}
																			</td>
																		);
																	})}
																</tr>
															))}

															<tr>
																<td colSpan={5} className="separator-row">
																	<strong>Last 20 Reviews</strong>
																</td>
															</tr>
															{reviewsLast20Settings.map((setting) => (
																<tr key={setting.label}>
																	<td className="description">{setting.label}</td>
																	{auditKeywordData.audit.map((cat, index) => {
																		let propValue =
																			cat.reviews &&
																			cat.reviews.last20 &&
																			cat.reviews.last20[setting.propValue]
																				? cat.reviews.last20[setting.propValue]
																				: null;
																		let printPropValue = propValue;
																		let isArray =
																			printPropValue &&
																			Array.isArray(printPropValue);
																		let isObject =
																			printPropValue &&
																			typeof printPropValue === 'object' &&
																			!isArray;
																		let showNumber = setting.responseType === 'int';

																		if (
																			typeof printPropValue == 'number' &&
																			!isNaN(printPropValue) &&
																			!Number.isInteger(printPropValue)
																		) {
																			printPropValue = parseFloat(
																				printPropValue
																			).toFixed(2);
																		}

																		return (
																			<td
																				key={index}
																				className={`business ${cat ===
																				'business'
																					? ''
																					: 'competitor'}`}
																			>
																				{printPropValue ? (
																					<Fragment>
																						{isArray ? (
																							<Fragment>
																								{printPropValue.length >
																								0 ? (
																									<button
																										className="btn btn-link p-0 btn-json-output"
																										onClick={() =>
																											printJsonNodeValue(
																												index,
																												setting
																											)}
																									>
																										<span>
																											{showNumber ? (
																												printPropValue.length
																											) : (
																												'Yes'
																											)}
																										</span>
																									</button>
																								) : (
																									<Fragment />
																								)}
																							</Fragment>
																						) : (
																							<Fragment>
																								{isObject ? (
																									<Fragment />
																								) : (
																									<button
																										className="btn btn-link p-0 btn-json-output"
																										onClick={() =>
																											printJsonNodeValue(
																												index,
																												setting
																											)}
																									>
																										<span>
																											{showNumber ? (
																												printPropValue
																											) : (
																												'Yes'
																											)}
																										</span>
																									</button>
																								)}
																							</Fragment>
																						)}
																					</Fragment>
																				) : (
																					<span className="json-output-no-value">
																						{showNumber ? (
																							0
																						) : (
																							<span className="json-output-no-value">
																								No
																							</span>
																						)}
																					</span>
																				)}
																			</td>
																		);
																	})}
																</tr>
															))}

															{/* Most Relevant Review Age */}
															<tr>
																<td className="description">
																	Most Relevant Review Age (Sort By Most Relevant)
																</td>
																{auditKeywordData.audit.map((cat, index) => {
																	let printSetting = {
																		label        : 'Most Relevant Review',
																		type         : 'reviews',
																		propValue    : 'mostRelevant',
																		propSubValue : 'first'
																	};
																	return (
																		<td
																			key={index}
																			className={`business ${cat === 'business'
																				? ''
																				: 'competitor'}`}
																		>
																			{cat.reviews &&
																			cat.reviews.mostRelevant &&
																			cat.reviews.mostRelevant.first &&
																			cat.reviews.mostRelevant.first.age ? (
																				<button
																					className="btn btn-link p-0 btn-json-output"
																					onClick={() =>
																						printJsonNodeValue(
																							index,
																							printSetting
																						)}
																				>
																					<span>
																						{cat.reviews.mostRelevant.first.age.replace(
																							' ago',
																							''
																						)}
																					</span>
																				</button>
																			) : (
																				''
																			)}
																		</td>
																	);
																})}
															</tr>
														</tbody>
													) : (
														<tbody>
															<tr>
																<td colSpan={5}>
																	<i>No Data</i>
																</td>
															</tr>
														</tbody>
													)}
												</table>
											</div>
										</div>
									</div>
								</div>
							</Fragment>
						) : (
							<Fragment>
								{(!gridData || isGenerating || isLoadingMap) && (
									<div className="loading-map">
										<Spinner />
									</div>
								)}

								{gridData && (
									<GridMapV5
										key="map-preview"
										mapIdValue="report-map-preview"
										gridData={gridData}
										placeId={gridData.googlePlaceId}
										isFullHeight={true}
										showCircle={true}
										circleRadius={areaRadius}
										onCircleMove={handleCircleMove}
										reportSettings={reportSettings}
									/>
								)}
							</Fragment>
						)}
					</Fragment>

					<div className="info-wrapper border border-top-0">
						{companyData &&
						companyData.googleBusinessUrl && (
							<Fragment>
								<div className="company-profile-url info-item">
									<span>
										<a
											className="btn btn-link p-0"
											target="_blank"
											href={companyData.googleBusinessUrl}
										>
											<span>Profile Url</span>
											<i className="fa-solid fa-up-right-from-square ms-2" />
										</a>
									</span>
								</div>
								<div className="info-item-separator">|</div>
							</Fragment>
						)}

						{selectedTask && (
							<Fragment>
								<div className="company-profile-url info-item">
									<span>Grid Size:</span>
									<span className="ms-2">{`${selectedTask.gridPoints}x${selectedTask.gridPoints}`}</span>
								</div>
								<div className="info-item-separator">|</div>
								<div className="company-profile-url info-item">
									<span>Radius:</span>
									<span className="ms-2">{`${areaRadius} miles`}</span>
								</div>
								<div className="info-item-separator">|</div>
								<div className="company-profile-url info-item">
									<span>Point Distance:</span>
									<span className="ms-2">{`${parseFloat(
										areaRadius * 2 / (selectedTask.gridPoints - 1)
									).toFixed(2)} miles`}</span>
								</div>
								<div className="info-item-separator">|</div>
								{showGridReport ? (
									<Fragment>
										{selectedTask &&
										selectedTask.lat &&
										selectedTask.lng && (
											<div className="company-profile-url info-item">
												<span>Lat/Lng:</span>
												<span className="ms-2">{`${selectedTask.lat}, ${selectedTask.lng}`}</span>
											</div>
										)}
									</Fragment>
								) : (
									<Fragment>
										{gridData &&
										gridData.lat &&
										gridData.lng && (
											<div className="company-profile-url info-item">
												<span>Lat/Lng:</span>
												<span className="ms-2">{`${gridData.lat}, ${gridData.lng}`}</span>
											</div>
										)}
									</Fragment>
								)}
							</Fragment>
						)}
					</div>

					{showJsonOutputDialog && (
						<ModalDialog className="text-start" position="right-sidebar">
							<ModalDialogContent align="start">
								<div className="row" dangerouslySetInnerHTML={{ __html: jsonOutput }} />
							</ModalDialogContent>
							<ModalDialogButtons>
								<div className="row">
									<div className="text-start col-12">
										<button className="btn btn-outline-primary" onClick={toggleJsonOutputDialog}>
											<span>Close</span>
										</button>
									</div>
								</div>
							</ModalDialogButtons>
						</ModalDialog>
					)}
				</div>
			</div>
		</div>
	);
};

export default GridReportTool;
